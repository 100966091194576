import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Api } from '../utils/api/api';
import { apiConfig } from '../utils/constants';
import { OrderDTO } from '@/types/OrderDTO';

class OrderService extends Api {
    public constructor(apiConfig: AxiosRequestConfig) {
        super(apiConfig);
        this.add = this.add.bind(this);
    }

    public add(dto: OrderDTO): Promise<boolean> {
        return this.post(`Order`, dto)
            .then((result: AxiosResponse<any>) => {
                return result.data
            });
    }
}

export const orderApi = new OrderService(apiConfig);